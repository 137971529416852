"use client";
import React, { useEffect, useRef } from "react";
import {
  sendMessage,
  sendMessageToBackend,
  transfer,
  getAgentMessages,
  endChat,
  sendMessageToSystem,
  getStoresInfo,
  getStoreInfo,
  getSession,
} from "../services/chatService";
import {
  CHAT_CLOSED_MESSAGE,
  CHAT_END_INTERVAL,
  CHAT_END_MESSAGE,
  CHAT_INTENTS,
  CHAT_MESSAGE_VARIANTS,
  CHAT_TRANSFERED_MESSAGE,
  CHAT_TRANSFER_FAILED,
  CHAT_TRANSFER_MESSAGE,
  ERROR_MESSAGE,
  ICON_COLORS,
  ICON_PATHS,
  NO_RESPONSE_MESSAGE,
  POLLING_INTERVAL,
  REMINDER_INTERVAL,
  REMINDER_MESSAGE,
  SESSION_INTERVAL,
  SYSTEM_MESSAGE_CODES,
  TRANSFER_ACTIVE_MESSAGE,
  TRANSFER_MESSAGE_THRESHOLD,
} from "../constants";
import ChatContainer from "./Chat/ChatContainer";
import ChatWindowContainer from "./Chat/ChatWindowContainer";
import ChatWindowHeader from "./Chat/ChatWindowHeader";
import GELogo from "./Icons/GELogo";
import ChatWindowTitle from "./Chat/ChatWindowTitle";
import ChatWindowSubtitle from "./Chat/ChatWindowSubtitle";
import ChatTitleContainer from "./Chat/ChatTitleContainer";
import StyledButton from "./Buttons/StyledButton";
import ChatWindowMenu from "./Chat/ChatWindowMenu";
import RoundIcon from "./Icons/RoundIcon";
import ChatMessagesList from "./Chat/ChatMessagesList";
import ChatMessage from "./Chat/ChatMessage";
import ChatResponseLoader from "./Chat/ChatResponseLoader";
import ChatWindowFooter from "./Chat/ChatWindowFooter";
import StyledTextBox from "./Inputs/StyledTextBox";
import ChatMinimized from "./Chat/ChatMinimized";
import styled from "styled-components";
import StoresList from "./Chat/StoresList";

let reminderUpdate = setTimeout(() => {}, REMINDER_INTERVAL);
let chatEndUpdate = setTimeout(() => {}, CHAT_END_INTERVAL);
let sessionUpdate = () => {};
let historyUpdate = () => {};
const STORE_INTENT_THRESHOLD = 3;

export default function Chat() {
  const [showWindow, setShowWindow] = React.useState(false);
  const [messages, setMessages] = React.useState([]);
  const [userMessage, setMessage] = React.useState("");
  const [userGuid, setUserGuid] = React.useState(
    Math.random().toString(36).substring(7)
  );
  const [responseText, setResponseText] = React.useState({});
  const [showChatLoader, setShowChatLoader] = React.useState(false);
  const [backend_id, setBackendId] = React.useState(null);
  const [agentChatHistory, setAgentChatHistory] = React.useState([]);
  const [showTransferButton, setShowTransferButton] = React.useState(false);
  const [transferDisabled, setTransferDisabled] = React.useState(false);
  const [chatEnded, setChatEnded] = React.useState(false);
  const [isTimerRunning, setIsTimerRunning] = React.useState(false);
  const [selectedStore, setSelectedStore] = React.useState(null);
  const chatIntent = useRef(CHAT_INTENTS.BOT);
  const messagesEndRef = useRef(null);
  const backend_id_ref = useRef(backend_id);
  const isTimerRunningRef = useRef(isTimerRunning);
  const storeIntentRetry = useRef(0);
  const location = useRef(null);
  const isLocationUsed = useRef(false);
  const [session, setSession] = React.useState(null);
  const [token, setToken] = React.useState(null);

  useEffect(() => {
    sessionUpdate = setInterval(() => {
      const userToken = sessionStorage.getItem("chatbot_user");
      if (userToken) {
        setToken(userToken);
        clearInterval(sessionUpdate);
      }
    }, SESSION_INTERVAL);
  }, []);

  useEffect(() => {
    const fetchSession = async () => {
      if (!session && token) {
        addBotMessage("Please wait while we fetch your details...");
        try {
          const res = await getSession(userGuid, token);
          setSession(res.session);
          if (res.session && res.session.first_name) {
            let welcomeMsg = `Welcome **${res.session.first_name}**!`;
            if (res.session.geac_number) {
              welcomeMsg = `${welcomeMsg} You have **${res.session.current_perks_points}** points in your loyalty account. How can I help you?`;
            }
            addBotMessage(welcomeMsg);
          } else {
            addBotMessage(
              "Welcome! We are unable to fetch your details at the moment but you can continue to chat with us."
            );
          }
        } catch (error) {
          addBotMessage(
            "Error fetching your details. You can continue to chat with us."
          );
        }
      }
    };
    fetchSession();
  }, [token]);

  useEffect(() => {
    backend_id_ref.current = backend_id;
  }, [backend_id]);

  useEffect(() => {
    isTimerRunningRef.current = isTimerRunning;
  }, [isTimerRunning]);

  useEffect(() => {
    return () => {
      clearInterval(historyUpdate);
    };
  }, []);

  useEffect(() => {
    const selectionChanged = async () => {
      if (selectedStore) {
        chatIntent.current = CHAT_INTENTS.SINGLE_STORE;
        scrollToBottom();
        await handleMessage("Get store details", true);
        addBotMessage(
          `Would you like to know anything specific about the **${selectedStore.name}** store?`,
          CHAT_MESSAGE_VARIANTS.MARK_DOWN
        );
      }
    };
    selectionChanged();
  }, [selectedStore]);

  useEffect(() => {
    if (chatEnded) {
      endChat(userGuid, backend_id);
      setTransferDisabled(true);
      clearInterval(historyUpdate);
      clearTimeout(reminderUpdate);
      clearTimeout(chatEndUpdate);
    }
  }, [chatEnded]);

  useEffect(() => {
    if (backend_id === 0) {
      setChatEnded(true);
    }
  }, [backend_id]);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          location.current = { latitude, longitude };
        },
        (error) => {
          console.log("Error getting location", error);
        }
      );
    } else {
      addBotMessage("Geolocation is not supported by this browser.");
    }
  };

  const handleBotMessage = (message) => {
    const defaultMessage = { message: "", variant: "" };
    if (!message) {
      return defaultMessage;
    }
    addBotMessage(message);

    if (
      showTransferButton === false &&
      (messages.length > TRANSFER_MESSAGE_THRESHOLD - 1 ||
        message.includes(NO_RESPONSE_MESSAGE))
    ) {
      setShowTransferButton(true);
      return { message: TRANSFER_ACTIVE_MESSAGE };
    }

    return defaultMessage;
  };

  const handleBotResponse = async (result) => {
    let messageObject = { message: "", variant: "" };
    switch (true) {
      case result?.transfer:
        handleTransfer(result.response);
        break;
      case result?.store:
        messageObject = await handleStores(result.response.query);
        break;
      default:
        messageObject = handleBotMessage(result.responseText);
    }
    return messageObject;
  };

  const addBotMessage = (message, variant) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        messageText: message,
        sender: "bot",
        variant: variant ?? CHAT_MESSAGE_VARIANTS.MARK_DOWN,
      },
    ]);
  };

  const handleStores = async (message) => {
    if (storeIntentRetry.current >= STORE_INTENT_THRESHOLD) {
      handleTransferClick();
      return { message: "", variant: "" };
    }
    // getLocation();

    const storesRes = await getStoresInfo(
      userGuid,
      message,
      isLocationUsed.current ? 0 : location.current?.latitude,
      isLocationUsed.current ? 0 : location.current?.longitude
    );

    if (location.current !== null) {
      isLocationUsed.current = true;
    }

    chatIntent.current = CHAT_INTENTS.MULTIPLE_STORE;

    if (storesRes.message) {
      storeIntentRetry.current += 1;

      let resMessage = storesRes.message;
      // if (location === null) {
      //   resMessage +=
      //     " You could also enable location to get the nearest stores.";
      //   getLocation();
      // }
      return {
        message: resMessage,
        variant: CHAT_MESSAGE_VARIANTS.MARK_DOWN,
      };
    } else {
      if (storesRes["Giant Eagle"].value.length === 0) {
        return {
          message: "No near by stores found",
          variant: CHAT_MESSAGE_VARIANTS.MARK_DOWN,
        };
      }
      const storesStr = (
        <StoresList
          storesRes={storesRes}
          selectedStore={selectedStore}
          onStoreSelectionChange={setSelectedStore}
        />
      );
      return {
        message: storesStr,
        variant: CHAT_MESSAGE_VARIANTS.OBJECT,
      };
    }
  };

  const handleTransfer = async (res) => {
    if (res.error) {
      addBotMessage(res.status);
      return;
    }

    const backendId = res.backend_id;
    if (backendId) {
      setBackendId(backendId);
      const agentMessages = await getAgentMessages(backendId);
      setAgentChatHistory(agentMessages);
      addBotMessage(CHAT_TRANSFERED_MESSAGE);
      historyUpdate = setInterval(() => {
        if (backendId !== null) {
          updateChatHistory(backendId);
        }
      }, POLLING_INTERVAL);
    }
  };

  const handleTransferClick = () => {
    setTransferDisabled(true);
    clearAutoChatTimeouts();
    addBotMessage(CHAT_TRANSFER_MESSAGE);
    transfer(userGuid)
      .then(async (res) => {
        await handleTransfer(res);
      })
      .catch(() => {
        setTransferDisabled(false);
        addBotMessage(CHAT_TRANSFER_FAILED);
      });
  };

  function clearAutoChatTimeouts() {
    clearTimeout(reminderUpdate);
    clearTimeout(chatEndUpdate);
    setIsTimerRunning(false);
  }

  const updateChatHistory = (backend_id) => {
    if (backend_id === null) {
      return;
    }
    getAgentMessages(backend_id).then((agentMessages) => {
      //Compare old chat history with new chat history and update the messages
      if (agentMessages.length === agentChatHistory.length) {
        return;
      }
      setAgentChatHistory((prevChatHistory) => {
        const newMessages = agentMessages.slice(prevChatHistory.length);
        if (
          newMessages.some((message) =>
            message.message.includes(CHAT_CLOSED_MESSAGE)
          )
        ) {
          setBackendId(0);
        }

        if (newMessages.length !== 0) {
          setMessages((prevMessages) => [
            ...prevMessages,
            ...newMessages.map((message) => ({
              messageText: message.message,
              sender: "bot",
            })),
          ]);
          setAutoChatTimeouts();
        }

        return agentMessages;
      });
    });
  };

  function setAutoChatTimeouts() {
    if (isTimerRunningRef.current) {
      return;
    }
    clearTimeout(reminderUpdate);
    reminderUpdate = setTimeout(() => {
      if (backend_id_ref.current !== null) {
        sendMessageToSystem(
          userGuid,
          backend_id_ref.current,
          SYSTEM_MESSAGE_CODES.FIVE_MINS
        );
      } else {
        addBotMessage(REMINDER_MESSAGE);
      }
    }, REMINDER_INTERVAL);

    clearTimeout(chatEndUpdate);
    chatEndUpdate = setTimeout(async () => {
      if (backend_id_ref.current !== null) {
        sendMessageToSystem(
          userGuid,
          backend_id_ref.current,
          SYSTEM_MESSAGE_CODES.TEN_MINS
        );
      } else {
        addBotMessage(CHAT_END_MESSAGE);
      }
      setTimeout(() => {
        setChatEnded(true);
      }, POLLING_INTERVAL + 1000);
    }, CHAT_END_INTERVAL);
    setIsTimerRunning(true);
  }

  const handleSingleStore = async (message, summary = false) => {
    const storeRes = await getStoreInfo(
      userGuid,
      message,
      selectedStore?.storeCoordinate?.coordinates[1] ?? 0,
      selectedStore?.storeCoordinate?.coordinates[0] ?? 0
    );
    if (storeRes?.end_conversation) {
      chatIntent.current = CHAT_INTENTS.BOT;
    }
    return {
      message: summary ? storeRes.summary : storeRes.response,
      variant: CHAT_MESSAGE_VARIANTS.MARK_DOWN,
    };
  };

  const handleBot = async (message) => {
    const botRes = await sendMessage(userMessage, userGuid);
    return await handleBotResponse(botRes);
  };

  const handleMessage = async (userMessage) => {
    let messageHandler;
    switch (true) {
      case chatIntent.current === CHAT_INTENTS.BOT:
        messageHandler = () => handleBot(userMessage);
        break;
      case chatIntent.current === CHAT_INTENTS.MULTIPLE_STORE:
        messageHandler = () => handleStores(userMessage);
        break;
      case chatIntent.current === CHAT_INTENTS.SINGLE_STORE:
        messageHandler = () => handleSingleStore(userMessage);
        break;
      default:
        messageHandler = () => handleBot(userMessage);
    }

    try {
      setShowChatLoader(true);
      const result = await messageHandler();
      if (result.message) {
        addBotMessage(result.message, result?.variant);
      }
      setAutoChatTimeouts();
    } catch (error) {
      console.error("Error during chat", error);
      addBotMessage(ERROR_MESSAGE);
    } finally {
      setShowChatLoader(false);
    }
  };

  const sendMessageClick = async (e) => {
    e.preventDefault();

    if (userMessage.trim() === "") {
      return;
    }
    setMessages([...messages, { messageText: userMessage, sender: "user" }]);
    setMessage("");

    clearAutoChatTimeouts();

    if (backend_id !== null) {
      sendMessageToBackend(userMessage, userGuid, backend_id);
      return;
    }

    await handleMessage(userMessage);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    setUserGuid(Math.random().toString(36).substring(7));
    // getLocation();
  }, []);

  const messagesList = messages.map((message, index) => {
    return (
      <ChatMessage
        key={index}
        type={message.sender}
        variant={message.variant ?? CHAT_MESSAGE_VARIANTS.MARK_DOWN}
      >
        {message.messageText}
      </ChatMessage>
    );
  });

  return (
    <ChatContainer showWindow={showWindow}>
      {showWindow ? (
        <ChatWindowContainer>
          <ChatWindowHeader>
            <GELogo />
            <ChatTitleContainer>
              <ChatWindowTitle>Welcome</ChatWindowTitle>
              <ChatWindowSubtitle>
                Giant Eagle Enhanced Chatbot
              </ChatWindowSubtitle>
            </ChatTitleContainer>
            <ChatWindowMenu>
              {showTransferButton ? (
                <StyledButton
                  data-testid="transferButton"
                  disabled={transferDisabled}
                  onClick={handleTransferClick}
                >
                  Transfer
                </StyledButton>
              ) : null}
              <UnstyledRoundButton
                data-testid="closeButton"
                onClick={() => setShowWindow(false)}
              >
                <RoundIcon src={ICON_PATHS.CLOSE} color={ICON_COLORS.WHITE} />
              </UnstyledRoundButton>
            </ChatWindowMenu>
          </ChatWindowHeader>
          <ChatMessagesList>
            {messagesList}
            {showChatLoader ? <ChatResponseLoader /> : null}
            <div ref={messagesEndRef} />
          </ChatMessagesList>
          <ChatWindowFooter>
            <StyledForm onSubmit={sendMessageClick}>
              <StyledTextBox
                disabled={chatEnded}
                onChange={(e) => setMessage(e.target.value)}
                value={userMessage}
                type="text"
                placeholder={
                  chatEnded ? "The chat has ended" : "Type your message..."
                }
              ></StyledTextBox>
              <UnstyledRoundButton data-testid="sendButton" type="submit">
                <RoundIcon
                  variant="md"
                  color={chatEnded ? ICON_COLORS.LIGHT_GRAY : ICON_COLORS.RED}
                  src={ICON_PATHS.SEND}
                />
              </UnstyledRoundButton>
            </StyledForm>
          </ChatWindowFooter>
        </ChatWindowContainer>
      ) : (
        <ChatMinimized
          onClick={() => {
            setShowWindow(true);
          }}
        />
      )}
    </ChatContainer>
  );
}

const UnstyledRoundButton = styled.button`
  padding: 0;
  background: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
`;

const StyledForm = styled.form`
  display: flex;
  width: 100%;
  gap: 8px;
`;
