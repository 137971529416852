import styled from "styled-components";
const ChatContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "showWindow",
})`
  position: fixed;
  bottom: 1%;
  font-family: sans-serif;
  z-index: 9999;
  max-height: 580px;
  max-width: 440px;
  overflow: auto;
  padding: 16px;
  height: ${(props) => (props.showWindow ? "100%" : "auto")};
  width: ${(props) => (props.showWindow ? "100%" : "auto")};
  @media (max-width: 450px) {
    max-width: 100%;
    max-height: 100%;
    bottom: ${(props) => (props.showWindow ? "0" : "1%")};
    padding: ${(props) => (props.showWindow ? "0px" : "16px")};
  }
`;

export default ChatContainer;
