import styled from "styled-components";
const StyledTextBox = styled.input.attrs((props) => ({
  // Set background color based on disabled prop
  style: {
    backgroundColor: props.disabled
      ? props.theme.bgColors.seconday
      : props.theme.bgColors.white,
    color: props.disabled
      ? props.theme.colors.darkGray
      : props.theme.colors.gray,
  },
}))`
  display: flex;
  width: 100%;
  padding: 10px 10px 10px 20px;
  align-items: center;
  border-radius: 52px;
  border: 1px solid var(--grayscale-gray-90-e-5-e-5-e-3, #e5e5e3);
  font-feature-settings: "clig" off, "liga" off;

  /* Body/Regular | 16 */
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

export default StyledTextBox;
