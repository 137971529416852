import styled from "styled-components";
const ButtonStyle = styled.button.attrs((props) => ({
  // Set background color based on disabled prop
  style: {
    background: props.disabled
      ? props.theme.bgColors.lightGray
      : props.theme.bgColors.red,
  },
}))`
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Body/Bold | 12 */
  font-family: "GE Inspira Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 133.333% */
  display: inline-flex;
  max-width: 240px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 80px;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
`;

const StyledButton = (props) => {
  return <ButtonStyle {...props}>{props.children}</ButtonStyle>;
};

export default StyledButton;
