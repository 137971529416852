import { createGlobalStyle } from "styled-components";
const baseUrl = process.env.REACT_APP_BASE_URL;

export const theme = {
  bgColors: {
    red: "var(--Tertiary-Gradient-Red, linear-gradient(180deg, #E2162E 0%, #C8102E 100%))",
    lightGray: "var(--grayscale-gray-96-f-0-eeea, #F0EEEB)",
    gray: "var(--grayscale-gray-302-c-3337, #2C3337)",
    white: "var(--grayscale-white-ffffff, #FFF)",
  },
  colors: {
    gray: "var(--grayscale-gray-302-c-3337, #2C3337)",
    white: "var(--grayscale-white-ffffff, #FFF)",
    darkGray: "var(--grayscale-gray-506-c-7275, #6C7275)",
  },
  hoverColors: {
    gray: "#F9F8F6",
    lightRed: "#C8102E",
  },
  fonts: {
    primary: "Inter, sans-serif",
    secondary: "AvantGardeGothicITC, sans-serif",
  },
  boxBorder: "1px solid var(--grayscale-gray-96-f-0-eeea, #f0eeeb)",
};

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-style: normal;
  }
  @font-face {
    font-family: "Inter var";
    font-weight: 400 900;
    font-style: oblique 0deg 10deg;
    font-display: swap;
    src: url(${baseUrl}/fonts/inter.var.woff2) format("woff2-variations"),
         url(${baseUrl}/fonts/inter.var.woff2) format("woff2");
  }

  @font-face {
    font-family: "Inter var alt";
    font-weight: 400 900;
    font-style: normal;
    font-display: swap;
    src: url(${baseUrl}/fonts/inter.normal.var.woff2) format("woff2 supports variations(gvar)"),
         url(${baseUrl}/fonts/inter.normal.var.woff2) format("woff2-variations"),
         url(${baseUrl}/fonts/inter.normal.var.woff2) format("woff2");
  }

  @font-face {
    font-family: "Inter var alt";
    font-weight: 400 900;
    font-style: italic;
    font-display: swap;
    src: url(${baseUrl}/fonts/inter.italic.var.woff2) format("woff2 supports variations(gvar)"),
         url(${baseUrl}/fonts/inter.italic.var.woff2) format("woff2-variations"),
         url(${baseUrl}/fonts/inter.italic.var.woff2) format("woff2");
  }

  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Inter"),
         url(${baseUrl}/fonts/inter.400.woff2) format("woff2"),
         url(${baseUrl}/fonts/inter.400.woff) format("woff");
  }

  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(${baseUrl}/fonts/inter.700.woff2) format("woff2"),
         url(${baseUrl}/fonts/inter.700.woff) format("woff");
  }

  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(${baseUrl}/fonts/inter.900.woff2) format("woff2"),
         url(${baseUrl}/fonts/inter.900.woff) format("woff");
  }

  @font-face {
    font-family: "AvantGardeGothicITC";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(${baseUrl}/fonts/itc-avant-garde-gothic-bold.woff2) format("woff2"),
         url(${baseUrl}/fonts/itc-avant-garde-gothic-bold.woff) format("woff");
  }

  @font-face {
    font-family: "Font Awesome Pro";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(${baseUrl}/fonts/fa.400.woff2) format("woff2"),
         url(${baseUrl}/fonts/fa.400.woff) format("woff");
  }

  @font-face {
    font-family: "Font Awesome 5 Brands";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(${baseUrl}/fonts/fa-brands-400.woff2) format("woff2"),
         url(${baseUrl}/fonts/fa-brands-400.woff) format("woff"),
         url(${baseUrl}/fonts/fa-brands-400.ttf) format("truetype"),
         url(${baseUrl}/fonts/fa-brands-400.svg#fontawesome) format("svg");
  }

  body, textarea {
    font-family: "Inter", -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif;
  }

  @supports (font-variation-settings: normal) {
    body {
      font-family: "Inter var", "Inter", -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif;
    }
    html.is-edge body,
    html.is-safari body {
      font-family: "Inter var alt", "Inter var", "Inter", -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif;
    }
  }

  .font-awesome {
    font-family: "Font Awesome Pro", sans-serif;
    font-weight: 400 !important;
  }
  .font-awesome.fw-b, .font-awesome.thick {
    font-weight: 700 !important;
  }
  .font-awesome.shrink {
    font-size: 0.8em;
    font-weight: inherit !important;
  }
  .font-awesome.brands {
    font-family: "Font Awesome 5 Brands", -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
  }
  .mono {
    font-family: "Fira Code", "Fira Mono", "Inconsolata", "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  }
`;

export default theme;
