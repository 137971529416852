import styled from "styled-components";
const ChatWindowSubtitle = styled.div`
  color: ${(props) => props.theme.colors.gray};
  font-feature-settings: "clig" off, "liga" off;

  /* Body/Regular | 12 */
  font-weight: 400;
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 12px;
  line-height: 16px; /* 133.333% */
  text-align: left;
`;

export default ChatWindowSubtitle;
