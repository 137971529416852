import styled from "styled-components";
const ChatWindowHeader = styled.div`
  height: 72px;
  flex-shrink: 0;
  padding: 0 16px;
  border-bottom: ${(props) => props.theme.boxBorder};
  background: #fff;
  display: flex;
  display-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
`;

export default ChatWindowHeader;
