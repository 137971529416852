import styled from "styled-components";
import RoundIcon from "../Icons/RoundIcon";
import { ICON_COLORS, ICON_PATHS } from "../../constants";

const ChatLoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const ChatResponseLoaderStyle = styled.div`
  color: ${(props) => props.theme.colors.gray};
  font-feature-settings: "clig" off, "liga" off;

  /* Body/Bold | 12 */
  font-weight: 700;
  font-size: 12px;
  line-height: 16px; /* 133.333% */
  text-align: left;
`;

const ChatMessageIcon = styled(RoundIcon)`
  padding: 9px 9px 7px 7px;
`;

const ChatResponseLoader = () => {
  return (
    <ChatLoaderContainer>
      <ChatMessageIcon color={ICON_COLORS.LIGHT_GRAY} src={ICON_PATHS.BIRD} />
      <ChatResponseLoaderStyle>Generating answer...</ChatResponseLoaderStyle>
    </ChatLoaderContainer>
  );
};
export default ChatResponseLoader;
