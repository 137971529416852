import React from "react";
import styled from "styled-components";
import { BotMessageContainer } from "./ChatMessage";

const StoreCardContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  margin: 8px 0;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: left;
  background-color: ${({ selected }) => (selected ? "#cce5ff" : "white")};

  p {
    margin: 0.5rem 0;
  }
  &:hover {
    background-color: #f0f0f0;
  }
`;

const StoreListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 8px 8px 8px 0px;
  background: ${(props) => props.theme.bgColors.lightGray};
`;

const StoreCard = ({ store, selectStore, isSelected }) => (
  <StoreCardContainer
    onClick={() => {
      selectStore(store);
    }}
    selected={isSelected}
  >
    <p>
      <strong>{store.name}</strong>
    </p>
    <p>{store.addressLineOne}</p>
    <p>{`${store.locationCity}, ${store.addressState} ${store.addressZip}`}</p>
  </StoreCardContainer>
);

const StoresList = ({
  storesRes,
  selectedStoreName,
  onStoreSelectionChange,
}) => {
  const formatStoreList = (storeList, storeType) => {
    if (storeList?.value?.length > 0) {
      return storeList.value.map((store, index) => (
        <StoreCard
          key={store.name}
          store={store}
          selectStore={onStoreSelectionChange}
          isSelected={selectedStoreName === store.name}
        />
      ));
    }
    return null; // Handle empty store lists
  };

  return (
    <BotMessageContainer>
      <StoreListContainer>
        <p>Click on a store you to know more!</p>
        {formatStoreList(storesRes["Giant Eagle"], "Giant Eagle")}
        {formatStoreList(storesRes.GetGo, "GetGo")}
      </StoreListContainer>
    </BotMessageContainer>
  );
};

export default StoresList;
