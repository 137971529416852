import styled from "styled-components";
import { ICON_PATHS } from "../../constants";
const GELogoStyle = styled.img`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const GELogo = () => {
  return <GELogoStyle src={ICON_PATHS.LOGO} />;
};

export default GELogo;
