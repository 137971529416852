import { CHAT_BASE_URL } from "../constants";

export const sendMessage = async (message, id = 1234) => {
  const response = await fetch(`${CHAT_BASE_URL}/api/sendToBot?id=${id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      text: message,
      timestamp: new Date().toISOString(),
    }),
  });

  return response.json();
};

export const transfer = async (id) => {
  try {
    const response = await fetch(`${CHAT_BASE_URL}/api/transfer?id=${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.json();
  } catch (error) {
    console.log("Error during transfer", error);
    return null;
  }
};

export const sendMessageToBackend = async (message, id, backend_id) => {
  fetch(`${CHAT_BASE_URL}/api/sendToAgent?id=${id}&backend_id=${backend_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      text: message,
      timestamp: new Date().toISOString(),
    }),
  });
};

export const sendMessageToSystem = async (id, backend_id, messageCode) => {
  fetch(
    `${CHAT_BASE_URL}/api/sendToSystem?id=${id}&backend_id=${backend_id}&message_code=${messageCode}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const getChatHistory = async (backend_id) => {
  const response = await fetch(
    `${CHAT_BASE_URL}/api/history?backend_id=${backend_id}`,
    {
      method: "GET",
    }
  );

  return response.json();
};

export const endChat = async (id, backend_id) => {
  const response = await fetch(
    `${CHAT_BASE_URL}/api/endChat?id=${id}&backend_id=${backend_id ?? 0}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response.json();
};

export const getAgentMessages = async (backend_id) => {
  const chatMessages = await getChatHistory(backend_id);
  return chatMessages.filter((msg) => msg.user_id != "0");
};

export const getStoresInfo = async (id, message, lat = 0, long = 0) => {
  const response = await fetch(
    `${CHAT_BASE_URL}/api/getStores?request_id=${id}&query=${message}&lat=${lat}&long=${long}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const data = response.json();
  return data;
};

export const getStoreInfo = async (id, message, lat, long) => {
  const response = await fetch(
    `${CHAT_BASE_URL}/api/getStore?request_id=${id}&query=${message}&lat=${lat}&long=${long}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const data = response.json();
  return data;
};

export const getSession = async (id, token) => {
  const response = await fetch(`${CHAT_BASE_URL}/api/session`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: id,
      token: token,
    }),
  });

  return response.json();
};
