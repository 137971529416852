import styled from "styled-components";
const ChatWindowContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 540px;
  min-width: 400px;
  border-radius: 24px;
  border: ${(props) => props.theme.boxBorder};
  background: #fff;
  overflow: hidden;
  /* Shadow/Depth 2 */
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 450px) {
    min-width: 100%;
  }

  @media (max-width: 288px) {
    min-width: 288px;
  }
`;

export default ChatWindowContainer;
