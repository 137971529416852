import styled from "styled-components";
import { ICON_COLORS } from "../../constants";
const getBackGroundColor = (color, theme) => {
  switch (color) {
    case ICON_COLORS.LIGHT_GRAY:
      return theme.bgColors.lightGray;
    case ICON_COLORS.WHITE:
      return theme.bgColors.white;
    case ICON_COLORS.RED:
      return theme.bgColors.red;
    default:
      return theme.bgColors.red;
  }
};

const getHoverBackGroundColor = (color, theme) => {
  switch (color) {
    case ICON_COLORS.WHITE:
      return theme.hoverColors.gray;
    case ICON_COLORS.RED:
      return theme.hoverColors.lightRed;
    default:
      return theme.bgColors.lightGray;
  }
};

const getSize = (variant) => {
  switch (variant) {
    case "sm":
      return "40px";
    case "md":
      return "48px";
    default:
      return "40px";
  }
};

const RoundIconStyle = styled.div`
  display: flex;
  width: ${(props) => getSize(props.variant)};
  height: ${(props) => getSize(props.variant)};
  max-width: 240px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  border: 1px solid var(--grayscale-gray-90-e-5-e-5-e-3, #e5e5e3);
  background: ${(props) => getBackGroundColor(props.color, props.theme)};
  &:hover {
    background: ${(props) => getHoverBackGroundColor(props.color, props.theme)};
  }
`;

const RoundIcon = (props) => {
  return (
    <RoundIconStyle {...props}>
      <img src={props.src} />
    </RoundIconStyle>
  );
};

export default RoundIcon;
