import styled from "styled-components";
const ChatWindowFooter = styled.div`
  height: 72px;
  flex-shrink: 0;
  padding: 0 8px;
  border-top: ${(props) => props.theme.boxBorder};
  background: #fff;
  display: flex;
  display-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export default ChatWindowFooter;
