import { MessageSquare } from "lucide-react";
import styled from "styled-components";

const ChatMinimizedStyle = styled.button`
  display: flex;
  cursor: pointer;
  background: ${(props) => props.theme.bgColors.red};
  border: none;
  border-radius: 50%;
  padding: 12px;
`;

const ChatMinimized = (props) => {
  return (
    <ChatMinimizedStyle {...props}>
      <MessageSquare
        data-testid="messageSquare"
        size={24}
        fill="white"
        color="#ffffff"
        strokeWidth={0.5}
      />
    </ChatMinimizedStyle>
  );
};
export default ChatMinimized;
