export const REMINDER_MESSAGE =
  "Haven't heard anything from you since 5 mins. Are you still there with us?";
export const CHAT_END_MESSAGE =
  "It’s been 10 minutes since we heard from you, At this time we will close this chat but feel free to reach out to us again if you need any help. Thank you for being a Giant Eagle customer.";
export const CHAT_CLOSED_MESSAGE = "has closed the chat";
export const CHAT_TRANSFER_MESSAGE = "Transfering the chat to an agent...";
export const CHAT_TRANSFERED_MESSAGE =
  "Chat has been transfered to an agent. Our agent will assist you soon.";
export const CHAT_TRANSFER_FAILED =
  "Failed to transfer the chat to an agent. Please try again later.";
export const REMINDER_INTERVAL = 300000;
export const CHAT_END_INTERVAL = 600000;
export const TRANSFER_MESSAGE_THRESHOLD = 10;
export const POLLING_INTERVAL = 10000;
export const SESSION_INTERVAL = 2000;
export const ERROR_MESSAGE =
  "Unable to serve you at the moment! Please try later!";
export const NO_RESPONSE_MESSAGE = "try another query or topic";
export const CHAT_BASE_URL = process.env.REACT_APP_BASE_URL;
export const TRANSFER_ACTIVE_MESSAGE =
  "Please click the transfer button on top right corner anytime you wish to talk to our customer care agent";
export const ICON_PATHS = {
  ACCOUNT: `${CHAT_BASE_URL}/assets/icons/Account.svg`,
  BIRD: `${CHAT_BASE_URL}/assets/icons/Bird.svg`,
  CLOSE: `${CHAT_BASE_URL}/assets/icons/Close.svg`,
  SEND: `${CHAT_BASE_URL}/assets/icons/Send.svg`,
  LOGO: `${CHAT_BASE_URL}/assets/icons/GE_logo_sm.svg`,
};
export const ICON_COLORS = {
  RED: "red",
  LIGHT_GRAY: "lightGray",
  WHITE: "white",
};
export const SYSTEM_MESSAGE_CODES = {
  FIVE_MINS: 0,
  TEN_MINS: 1,
};
export const CHAT_MESSAGE_VARIANTS = {
  MARK_DOWN: "markDown",
  TEXT: "text",
  OBJECT: "object",
  IMAGE: "image",
};
export const CHAT_INTENTS = {
  BOT: "bot",
  AGENT: "agent",
  MULTIPLE_STORE: "multipleStore",
  SINGLE_STORE: "singleStore",
};
