import styled, { css } from "styled-components";
import RoundIcon from "../Icons/RoundIcon";
import {
  CHAT_MESSAGE_VARIANTS,
  ICON_COLORS,
  ICON_PATHS,
} from "../../constants";
import ReactMarkdown from "react-markdown";

const MessageStyle = styled.div`
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 12px;
  /* Body/Regular | 12 */
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  word-wrap: break-word;
  min-width: 50px;
  max-width: 275px;
  text-align: left;
  p {
    margin: 0;
  }
  * {
    font-size: 12px;
  }
  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    unicode-bidi: isolate;
  }

  h3 {
    margin: 12px 0px;
  }
`;

const UserMessageStyle = styled(MessageStyle)`
  border-radius: 8px 8px 0px 8px;
  background: ${(props) => props.theme.bgColors.gray};
  color: ${(props) => props.theme.colors.white};
`;

const BotMessageStyle = styled(MessageStyle)`
  border-radius: 8px 8px 8px 0px;
  background: ${(props) => props.theme.bgColors.lightGray};
  color: ${(props) => props.theme.colors.gray};
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: end;
  gap: 8px;
`;

const UserMessageContainer = styled(MessageContainer)`
  margin-left: auto;
  flex-direction: row;
`;

export const BotMessageContainer = styled(MessageContainer)`
  margin-right: auto;
  flex-direction: row-reverse;
`;

const ChatMessageIcon = styled(RoundIcon)`
  ${(props) =>
    !props.$isUser &&
    css`
      padding: 9px 9px 7px 7px;
    `}
`;

const ChatMessage = ({ children, type, variant }) => {
  const ChatMessageStyle = type === "user" ? UserMessageStyle : BotMessageStyle;
  const ChatMessageContainer =
    type === "user" ? UserMessageContainer : BotMessageContainer;
  return (
    <ChatMessageContainer type={type}>
      {variant === CHAT_MESSAGE_VARIANTS.MARK_DOWN && (
        <ChatMessageStyle type={type}>
          <ReactMarkdown>{children}</ReactMarkdown>
        </ChatMessageStyle>
      )}
      {variant === CHAT_MESSAGE_VARIANTS.OBJECT && children}
      <ChatMessageIcon
        color={ICON_COLORS.LIGHT_GRAY}
        src={type === "user" ? ICON_PATHS.ACCOUNT : ICON_PATHS.BIRD}
        $isUser={type === "user"}
      />
    </ChatMessageContainer>
  );
};
export default ChatMessage;
