import styled from "styled-components";
const ChatMessagesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: auto;
  padding: 8px;
  height: 100%;
`;

export default ChatMessagesList;
