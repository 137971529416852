import styled from "styled-components";
const ChatWindowTitle = styled.div`
  color: ${(props) => props.theme.colors.gray};
  font-feature-settings: "clig" off, "liga" off;

  /* Heading/Heading | 16 */
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 16px;
  font-weight: 700;
  line-height: 20px; /* 125% */
  text-align: left;
`;

export default ChatWindowTitle;
