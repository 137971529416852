import React from "react";
import styled from "styled-components";

const StyledImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 250px;
`;
const ImageMessage = ({ src, href, header, footer }) => {
  return (
    <StyledImageContainer>
      {header && <p>{header}</p>}
      <a href={href} target="_blank">
        <StyledImage src={src}></StyledImage>
      </a>
      {footer && <p>{footer}</p>}
    </StyledImageContainer>
  );
};
export default ImageMessage;
